<!-- 订单列表 -->
<template>
  <div class="page-main">
    <div class="logo-module">
      <van-image
        width="100px"
        height="100px"
        fit="cover"
        radius="5"
        :src="require('@/assets/image/organ-logo.png')"
      />
      <div class="organ-name">
        {{organInfo.organ_name}}
      </div>
    </div>
    <div class="login-module">
      <van-form @submit="onSubmit">
        <van-cell-group inset :border="false">
          <div class="input-card">
            <van-icon name="contact" size="30" color="#409EFF" />
            <van-field
              v-model="login_name"
              placeholder="请输入用户名"
              clearable
              :rules="[{ required: true }]"
            />
          </div>
          <div class="input-card">
            <van-icon name="lock" size="30" color="#409EFF" />
            <van-field
              v-model="password"
              type="password"
              placeholder="请输入密码"
              clearable
              :rules="[{ required: true }]"
            />
          </div>
        </van-cell-group>
        <div>
          <van-button round block type="primary" native-type="submit">
            登 录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getStorage, setStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonMgrData'
import organ from "@/api/ofsMgr/organ"
import login from "@/api/ofsMgr/login"
import sha256 from 'js-sha256'

export default {
  name: "orderMgrLogin",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      document.title = '订单管理系统'
      if (getStorage(CommonData.KeyStorages.KEY_MGR_IS_LOGIN) &&
        getStorage(CommonData.KeyStorages.KEY_MGR_USER_ID) &&
        getStorage(CommonData.KeyStorages.KEY_MGR_USER_TOKEN)
      ) {
        $router.push({
          name: "orderMgrHome"
        })
      } else {
        getOrganInfo()
      }
    });

    const data = reactive({
      login_name: '',
      password: '',
      organInfo: {}
    });

    const getOrganInfo = () => {
      organ.mainInfo({}).then(res => {
        console.log(res)
        data.organInfo = res.data
      })
    }
    const onSubmit = () => {
      console.log('onSubmit')
      const post_data = {
        login_name: data.login_name,
        password: sha256(encodeURIComponent(data.password))
      }
      console.log(post_data)
      login.login(post_data).then(res => {
        console.log(res)
        // 存储用户Id、token及登录状态
        setStorage(CommonData.KeyStorages.KEY_MGR_USER_TOKEN, res.data.token)
        setStorage(CommonData.KeyStorages.KEY_MGR_USER_ID, res.data.user_id)
        setStorage(CommonData.KeyStorages.KEY_MGR_USER_NAME, res.data.user_name)
        setStorage(CommonData.KeyStorages.KEY_MGR_CANTEEN_ID, res.data.user_name)
        setStorage(CommonData.KeyStorages.KEY_MGR_IS_LOGIN, true)
        $router.push({
          name: "orderMgrHome"
        })
      })
    }

    return {
      ...toRefs(data),
      onSubmit
    };
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .logo-module {
    margin-top: 33%;
    width: 100%;
    text-align: center;
    .organ-name {
      margin-top: 15px;
      font-size: 28px;
    }
  }
  .login-module {
    margin-top: 45px;
    padding: 0 20px;
    .input-card {
      padding: 0 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: center;
      border: 1px solid #409EFF;
      border-radius: 20px;
    }
  }
}
</style>

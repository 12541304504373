import api from '../mgrApiIndex'

const organ = {
    // 登录
    mainInfo(data) {
        return api({
            url: 'v2/mgr/organ-info',
            method: 'post',
            data: data
        })
    },
    userInfo(data) {
        return api({
            url: 'v2/user/info',
            method: 'post',
            data: data
        })
    }
}
export default organ

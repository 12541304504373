import api from '../mgrApiIndex'

const login = {
    // 登录
    login(data) {
        return api({
            url: 'v2/mgr/login-in',
            method: 'post',
            data: data
        })
    },
    // 登录
    loginOut(data) {
        return api({
            url: 'v2/mgr/login-out',
            method: 'post',
            data: data
        })
    }
}
export default login

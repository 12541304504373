// 常量定义
module.exports = {
  KeyStorages: {
    KEY_MGR_USER_ID: "_KEY_MGR_USER_ID_",
    KEY_MGR_USER_TOKEN: "_KEY_MGR_USER_TOKEN_",
    KEY_MGR_USER_NAME: "_KEY_MGR_USER_NAME_",
    KEY_MGR_IS_LOGIN: "_KEY_MGR_IS_LOGIN_",
    KEY_MGR_CANTEEN_ID: "_KEY_MGR_CANTEEN_ID_",
  },

}
